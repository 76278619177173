<template>
  <div v-loading="loading" element-loading-text="数据加载中..." element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)" @click.self="handleOutsideClick">
    <div class="search-box">
      <!-- 添加平台筛选到顶部 -->
      <div class="platform-filter">
        <div class="platform-tabs">
          <div class="platform-tab" :class="{ active: searchForm.plat === '' }" @click="handlePlatformChange('')">
            <img src="@/assets/quanbu.svg" alt="全部" class="platform-icon">
            <span>全部</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '1' }" @click="handlePlatformChange('1')">
            <img src="@/assets/douyin.svg" alt="抖音" class="platform-icon">
            <span>抖音</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '2' }" @click="handlePlatformChange('2')">
            <img src="@/assets/xiaohongshu.svg" alt="小红书" class="platform-icon">
            <span>小红书</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '3' }" @click="handlePlatformChange('3')">
            <img src="@/assets/kuaishou.svg" alt="快手" class="platform-icon">
            <span>快手</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '4' }" @click="handlePlatformChange('4')">
            <img src="@/assets/bili.svg" alt="B站" class="platform-icon">
            <span>B站</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '5' }" @click="handlePlatformChange('5')">
            <img src="@/assets/weixin_video.svg" alt="视频号" class="platform-icon">
            <span>视频号</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '6' }" @click="handlePlatformChange('6')">
            <img src="@/assets/weixin_mp.svg" alt="公众号" class="platform-icon">
            <span>公众号</span>
          </div>
          <div class="platform-tab" :class="{ active: searchForm.plat === '7' }" @click="handlePlatformChange('7')">
            <img src="@/assets/weibo.svg" alt="微博" class="platform-icon">
            <span>微博</span>
          </div>
        </div>
      </div>

      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item>
          <el-input v-model.trim="searchForm.nick_name" placeholder="请输入达人昵称" clearable @keyup.enter.native="handleSearch" @clear="handleClear" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.gender" placeholder="选择性别" clearable @change="handleSearch">
            <el-option label="男" value="1" />
            <el-option label="女" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" width="360" trigger="click" :close-on-click-modal="false" v-model="priceFilterVisible" @show="handlePricePopoverShow" popper-class="price-filter-popover">
            <div class="filter-panel">
              <!-- 价格类型选择 -->
              <div class="price-type-section">
                <div class="section-title">价格类型</div>
                <el-radio-group v-model="searchForm.price_type" class="price-type-group">
                  <el-radio label="">全部</el-radio>
                  <template v-if="searchForm.plat === '1' || searchForm.plat === '3'">
                    <!-- 抖音/快手 -->
                    <el-radio label="price_1_20">1-20s视频价格</el-radio>
                    <el-radio label="price_20_60">20-60s视频价格</el-radio>
                    <el-radio label="price_60">60s以上视频价格</el-radio>
                  </template>
                  <template v-else-if="searchForm.plat === '2'">
                    <!-- 小红书 -->
                    <el-radio label="price_1_20">图文报价</el-radio>
                    <el-radio label="price_20_60">视频报价</el-radio>
                  </template>
                  <template v-else-if="searchForm.plat === '4'">
                    <!-- B站 -->
                    <el-radio label="price_1_20">定制视频</el-radio>
                    <el-radio label="price_20_60">植入视频</el-radio>
                    <el-radio label="price_60">直发动态</el-radio>
                    <el-radio label="price_180">转发动态</el-radio>
                  </template>
                </el-radio-group>
              </div>

              <!-- 价格范围选择 -->
              <div class="price-range-section">
                <div class="section-title">价格范围</div>
                <!-- 添加预设价格范围 -->
                <div class="price-range-presets">
                  <el-radio-group v-model="selectedPriceRange" @change="handlePriceRangeChange">
                    <el-radio label="">全部</el-radio>
                    <el-radio label="10w+">10w以上</el-radio>
                    <el-radio label="5w-10w">5w-10w</el-radio>
                    <el-radio label="1w-5w">1w-5w</el-radio>
                    <el-radio label="5k-1w">5k-1w</el-radio>
                    <el-radio label="2k-5k">2k-5k</el-radio>
                    <el-radio label="0-2k">2k以下</el-radio>
                    <el-radio label="custom">自定义</el-radio>
                  </el-radio-group>
                </div>
                <!-- 自定义价格输入 -->
                <div v-if="selectedPriceRange === 'custom'" class="custom-range">
                  <el-input-number v-model="searchForm.min_price" :min="0" :controls="false" placeholder="最小值" />
                  <span>-</span>
                  <el-input-number v-model="searchForm.max_price" :min="0" :controls="false" placeholder="最大值" />
                  <span>元</span>
                </div>
              </div>

              <div class="filter-actions">
                <el-button @click="resetPriceFilter">重置</el-button>
                <el-button type="primary" @click="applyPriceFilter">确定</el-button>
              </div>
            </div>
            <el-button slot="reference" :type="hasPriceFilter ? 'primary' : ''">
              价格筛选 <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </el-popover>
        </el-form-item>
        <el-form-item>
          <el-popover placement="bottom" width="300" trigger="click" :close-on-click-modal="false" v-model="followerFilterVisible" @show="handleFollowerPopoverShow" popper-class="follower-filter-popover">
            <div class="filter-panel">
              <!-- 添加标题 -->
              <div class="section-title">粉丝数范围</div>
              <div class="follower-range-section">
                <div class="follower-range-presets">
                  <el-radio-group v-model="selectedFollowerRange" @change="handleFollowerRangeChange">
                    <el-radio label="">全部</el-radio>
                    <el-radio label="1000w+">1000w以上</el-radio>
                    <el-radio label="500w-1000w">500w-1000w</el-radio>
                    <el-radio label="100w-500w">100w-500w</el-radio>
                    <el-radio label="10w-100w">10w-100w</el-radio>
                    <el-radio label="1w-10w">1w-10w</el-radio>
                    <el-radio label="0-1w">1w以下</el-radio>
                    <el-radio label="custom">自定义</el-radio>
                  </el-radio-group>
                </div>
                <div v-if="selectedFollowerRange === 'custom'" class="custom-range">
                  <el-input-number v-model="searchForm.min_follower" :min="0" :controls="false" placeholder="最小值" />
                  <span>-</span>
                  <el-input-number v-model="searchForm.max_follower" :min="0" :controls="false" placeholder="最大值" />
                </div>
              </div>
              <div class="filter-actions">
                <el-button @click="resetFollowerFilter">重置</el-button>
                <el-button type="primary" @click="applyFollowerFilter">确定</el-button>
              </div>
            </div>
            <el-button slot="reference" :type="hasFollowerFilter ? 'primary' : ''">
              粉丝数筛选 <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <div class="search-header">
      <div class="kol-count">
        当前有 {{ pagination.total }} 个达人
      </div>
      <div class="export-actions">
        <!-- 添加AI智能筛选按钮 -->
        <el-button type="primary" size="mini" icon="el-icon-magic-stick" @click="openAiFilter" style="margin-right: 10px;">
          AI智能筛选
        </el-button>
        <el-button type="success" size="mini" @click="handleExport" :disabled="!exportList.length" :loading="openingExportDialog">
          清单 ({{ exportList.length }})
        </el-button>
      </div>
    </div>

    <!-- 添加已选筛选条件展示区域 -->
    <div v-if="hasActiveFilters" class="active-filters">
      <div class="filter-tags">
        <el-tag v-if="searchForm.gender" closable @close="clearFilter('gender')" size="small">
          性别: {{ searchForm.gender === '1' ? '男' : '女' }}
        </el-tag>

        <el-tag v-if="searchForm.plat" closable @close="clearFilter('plat')" size="small">
          平台: {{ getPlatformName(searchForm.plat) }}
        </el-tag>

        <el-tag v-if="hasPriceFilter" closable @close="resetPriceFilter" size="small">
          价格: {{ getPriceFilterText }}
        </el-tag>

        <el-tag v-if="hasFollowerFilter" closable @close="resetFollowerFilter" size="small">
          粉丝数: {{ getFollowerFilterText }}
        </el-tag>
      </div>
      <el-button type="text" @click="clearAllFilters">清空</el-button>
    </div>
    <el-table v-loading="tableLoading" element-loading-text="数据加载中..." :data="kolList" style="width: 100%" @sort-change="handleSortChange" :row-class-name="tableRowClassName">
      <el-table-column prop="nick_name" label="达人信息" width="230" fixed="left">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center;">
            <el-image style="min-width: 40px; width: 40px; height: 40px; border-radius: 50%; margin-right: 10px; cursor: pointer;" :src="scope.row.avatar_uri" fit="cover" @click.native="goToKolDetail(scope.row)">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="user-info">
              <div class="nickname-text" @click="goToKolDetail(scope.row)" style="cursor: pointer; color: #f23e31;">
                {{ scope.row.nick_name }}
              </div>
              <div class="sub-info">
                {{ scope.row.gender === 1 ? '男' : scope.row.gender === 2 ? '女' : '' }}
                {{ (scope.row.gender === 1 || scope.row.gender === 2) && (scope.row.province || scope.row.city) ? '|' :
                  '' }}
                {{ scope.row.province }}{{ scope.row.city && scope.row.city !== scope.row.province ? scope.row.city : ''
                }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="plat" label="平台" width="70">
        <template slot-scope="scope">
          {{ getPlatformName(scope.row.plat) }}
        </template>
      </el-table-column>
      <el-table-column prop="follower" label="粉丝数" width="80" sortable="custom">
        <template slot-scope="scope">
          {{ formatNumber(scope.row.follower) }}
        </template>
      </el-table-column>


      <el-table-column prop="tags_relation" label="达人类型" width="120">
        <template slot-scope="scope">
          <div class="tags-container">
            <template v-if="scope.row.tags_relation">
              <template v-if="scope.row.plat === 2 && typeof scope.row.tags_relation === 'string' && scope.row.tags_relation.includes('、')">
                <!-- 小红书平台且有多个类型 -->
                <el-popover placement="top-start" trigger="hover" :content="scope.row.tags_relation">
                  <el-tag slot="reference" size="small">
                    {{ scope.row.tags_relation.split('、')[0] }}
                  </el-tag>
                </el-popover>
              </template>
              <template v-else>
                <!-- 其他平台或只有单个类型 -->
                <el-popover placement="top-start" trigger="hover" :content="getTagsSubCategories(scope.row.tags_relation)">
                  <el-tag slot="reference" size="small">
                    {{ getMainCategory(scope.row.tags_relation) }}
                  </el-tag>
                </el-popover>
              </template>
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="content_theme_labels_180d" label="内容主题" width="120">
        <template slot-scope="scope">
          <el-popover placement="top-start" trigger="hover" :content="Array.isArray(scope.row.content_theme_labels_180d) ? scope.row.content_theme_labels_180d.join('、') : typeof scope.row.content_theme_labels_180d === 'string' ? scope.row.content_theme_labels_180d : ''">
            <div slot="reference">
              <template v-if="Array.isArray(scope.row.content_theme_labels_180d) && scope.row.content_theme_labels_180d.length > 0">
                <el-tag v-for="(tag, index) in scope.row.content_theme_labels_180d.slice(0, 2)" :key="index" size="small" style="margin-right: 5px">
                  {{ tag }}
                </el-tag>
                <el-tag size="small" type="info" v-if="scope.row.content_theme_labels_180d.length > 2">
                  +{{ scope.row.content_theme_labels_180d.length - 2 }}
                </el-tag>
              </template>
              <template v-else-if="typeof scope.row.content_theme_labels_180d === 'string' && scope.row.content_theme_labels_180d">
                <el-tag size="small">{{ scope.row.content_theme_labels_180d }}</el-tag>
              </template>
              <span v-else>-</span>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="fans_increment_within_15d" label="涨粉量" sortable="custom">
        <template slot-scope="scope">
          {{ formatNumber(scope.row.fans_increment_within_15d) }}
        </template>
      </el-table-column>
      <el-table-column prop="fans_increment_rate_within_15d" label="涨粉率" sortable="custom">
        <template slot-scope="scope">
          {{ formatPercentage(scope.row.fans_increment_rate_within_15d) }}
        </template>
      </el-table-column>
      <el-table-column prop="interact_rate_within_30d" label="互动率" sortable="custom">
        <template slot-scope="scope">
          {{ formatPercentage(scope.row.interact_rate_within_30d) }}
        </template>
      </el-table-column>
      <el-table-column prop="play_over_rate_within_30d" label="完播率">
        <template slot-scope="scope">
          {{ formatPercentage(scope.row.play_over_rate_within_30d) }}
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.plat === 4">
            <!-- B站价格显示 -->
            <div v-if="scope.row.price_1_20 && scope.row.price_1_20 !== 0">
              <span>定制视频：</span>
              <span>¥{{ scope.row.price_1_20 }}</span>
            </div>
            <div v-if="scope.row.price_20_60 && scope.row.price_20_60 !== 0">
              <span>植入视频：</span>
              <span>¥{{ scope.row.price_20_60 }}</span>
            </div>
            <div v-if="scope.row.price_60 && scope.row.price_60 !== 0">
              <span>直发动态：</span>
              <span>¥{{ scope.row.price_60 }}</span>
            </div>
            <div v-if="scope.row.price_180 && scope.row.price_180 !== 0">
              <span>转发动态：</span>
              <span>¥{{ scope.row.price_180 }}</span>
            </div>
          </div>
          <div v-else-if="scope.row.plat === 2">
            <!-- 小红书价格显示 -->
            <div v-if="scope.row.price_1_20 && scope.row.price_1_20 !== 0">
              <span>图文报价：</span>
              <span>¥{{ scope.row.price_1_20 }}</span>
            </div>
            <div v-if="scope.row.price_20_60 && scope.row.price_20_60 !== 0">
              <span>视频报价：</span>
              <span>¥{{ scope.row.price_20_60 }}</span>
            </div>
          </div>
          <div v-else>
            <!-- 快手、抖音价格显示 -->
            <div v-if="scope.row.price_1_20 && scope.row.price_1_20 !== 0">
              <span>1-20s：</span>
              <span>¥{{ scope.row.price_1_20 }}</span>
            </div>
            <div v-if="scope.row.price_20_60 && scope.row.price_20_60 !== 0">
              <span>20-60s：</span>
              <span>¥{{ scope.row.price_20_60 }}</span>
            </div>
            <div v-if="scope.row.price_60 && scope.row.price_60 !== 0">
              <span>60s以上：</span>
              <span>¥{{ scope.row.price_60 }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updated_at" label="更新日期" width="100" />
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <div class="action-buttons">
            <el-button size="mini" type="primary" @click="addToExportList(scope.row)" v-if="!isInExportList(scope.row.star_id)" :loading="scope.row.addingToExport">
              加入清单
            </el-button>
            <el-button size="mini" type="danger" @click="removeFromExportList(scope.row.star_id)" v-else :loading="scope.row.removingFromExport">
              取消选择
            </el-button>
            <el-dropdown v-if="cartList.length > 0" @command="(command) => handleAddToCart(command, scope.row)" trigger="click" size="mini">
              <el-button size="mini" type="primary" :loading="scope.row.addingToCart">
                加入选号车 <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="cart in cartList" :key="cart.cart_id" :command="cart.cart_id">
                  {{ cart.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加分页组件 -->
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page" :page-sizes="[10, 20, 30]" :page-size="pagination.page_size" layout="total, sizes, prev, pager, next" :total="pagination.total" />
    </div>

    <!-- 添加清单弹窗 -->
    <el-dialog title="清单导出" :visible.sync="dialogVisible" width="600px">
      <div class="export-list-container">
        <div class="export-header">
          <span>共 {{ exportList.length }} 个达人</span>
          <el-button type="text" @click="clearExportList" style="color: #F56C6C;" :disabled="clearing" :loading="clearing">
            删除所有
          </el-button>
        </div>
        <el-table :data="exportList" style="width: 100%">
          <el-table-column width="60">
            <template slot-scope="scope">
              <el-image style="width: 40px; height: 40px; border-radius: 50%; cursor: pointer;" :src="scope.row.avatar_uri" fit="cover" @click.native="goToKolDetail(scope.row)">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="nick_name" label="达人昵称" />
          <el-table-column prop="plat" label="平台" width="80">
            <template slot-scope="scope">
              {{ getPlatformName(scope.row.plat) }}
            </template>
          </el-table-column>
          <el-table-column prop="follower" label="粉丝数" width="100">
            <template slot-scope="scope">
              {{ formatNumber(scope.row.follower) }}
            </template>
          </el-table-column>
          <el-table-column width="80">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="removeFromExportList(scope.row.star_id)" style="color: #F56C6C;" :disabled="scope.row.removingFromExport" :loading="scope.row.removingFromExport">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmExport" :disabled="!exportList.length || exporting" :loading="exporting">
          确认导出清单
        </el-button>
      </span>
    </el-dialog>

    <!-- 添加选号车相关弹窗 -->
    <el-dialog title="新建选号车" :visible.sync="newCartDialogVisible" width="400px">
      <el-form :model="newCartForm" ref="newCartForm" :rules="cartFormRules">
        <el-form-item label="选号车名称" prop="name">
          <el-input v-model="newCartForm.name" placeholder="请输入选号车名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="newCartDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitNewCart">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="重命名选号车" :visible.sync="renameCartDialogVisible" width="400px">
      <el-form :model="renameCartForm" ref="renameCartForm" :rules="cartFormRules">
        <el-form-item label="选号车名称" prop="name">
          <el-input v-model="renameCartForm.name" placeholder="请输入选号车名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameCartDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRenameCart">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="选择价格类型" :visible.sync="priceTypeDialogVisible" width="400px">
      <el-form :model="priceTypeForm" ref="priceTypeForm">
        <el-form-item label="价格类型">
          <el-radio-group v-model="priceTypeForm.price_type">
            <template v-if="priceTypeForm.plat === 1 || priceTypeForm.plat === 3">
              <!-- 抖音/快手 -->
              <el-radio label="price_1_20" :disabled="!currentKolPrices.price_1_20 || currentKolPrices.price_1_20 === 0">
                1-20s视频价格 <span class="price-value" v-if="currentKolPrices.price_1_20">¥{{ currentKolPrices.price_1_20 }}</span>
              </el-radio>
              <el-radio label="price_20_60" :disabled="!currentKolPrices.price_20_60 || currentKolPrices.price_20_60 === 0">
                20-60s视频价格 <span class="price-value" v-if="currentKolPrices.price_20_60">¥{{ currentKolPrices.price_20_60 }}</span>
              </el-radio>
              <el-radio label="price_60" :disabled="!currentKolPrices.price_60 || currentKolPrices.price_60 === 0">
                60s以上视频价格 <span class="price-value" v-if="currentKolPrices.price_60">¥{{ currentKolPrices.price_60 }}</span>
              </el-radio>
            </template>
            <template v-else-if="priceTypeForm.plat === 2">
              <!-- 小红书 -->
              <el-radio label="price_1_20" :disabled="!currentKolPrices.price_1_20 || currentKolPrices.price_1_20 === 0">
                图文报价 <span class="price-value" v-if="currentKolPrices.price_1_20">¥{{ currentKolPrices.price_1_20 }}</span>
              </el-radio>
              <el-radio label="price_20_60" :disabled="!currentKolPrices.price_20_60 || currentKolPrices.price_20_60 === 0">
                视频报价 <span class="price-value" v-if="currentKolPrices.price_20_60">¥{{ currentKolPrices.price_20_60 }}</span>
              </el-radio>
            </template>
            <template v-else-if="priceTypeForm.plat === 4">
              <!-- B站 -->
              <el-radio label="price_1_20" :disabled="!currentKolPrices.price_1_20 || currentKolPrices.price_1_20 === 0">
                定制视频 <span class="price-value" v-if="currentKolPrices.price_1_20">¥{{ currentKolPrices.price_1_20 }}</span>
              </el-radio>
              <el-radio label="price_20_60" :disabled="!currentKolPrices.price_20_60 || currentKolPrices.price_20_60 === 0">
                植入视频 <span class="price-value" v-if="currentKolPrices.price_20_60">¥{{ currentKolPrices.price_20_60 }}</span>
              </el-radio>
              <el-radio label="price_60" :disabled="!currentKolPrices.price_60 || currentKolPrices.price_60 === 0">
                直发动态 <span class="price-value" v-if="currentKolPrices.price_60">¥{{ currentKolPrices.price_60 }}</span>
              </el-radio>
              <el-radio label="price_180" :disabled="!currentKolPrices.price_180 || currentKolPrices.price_180 === 0">
                转发动态 <span class="price-value" v-if="currentKolPrices.price_180">¥{{ currentKolPrices.price_180 }}</span>
              </el-radio>
            </template>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceTypeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddToCart" :disabled="!isValidPriceType || addingToCart" :loading="addingToCart">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改AI智能筛选对话框 -->
    <el-dialog title="AI智能筛选" :visible.sync="aiFilterDialogVisible" width="70%" :before-close="handleAiFilterDialogClose" :close-on-click-modal="false" :destroy-on-close="false">
      <!-- 输入表单，只在未加载且无结果时显示 -->
      <el-form v-if="!aiFilterLoading && !aiFilterStreamingResult">
        <el-form-item>
          <el-input v-model="aiFilterQuery" type="textarea" :rows="4" placeholder="请输入您的条件，例如：'产品名、品牌、分类和预算，我将为你筛选出符合条件的达人。'" :disabled="aiFilterLoading" @keyup.enter.native="submitAiFilter">
          </el-input>
        </el-form-item>
      </el-form>

      <!-- 在对话框内部显示流式结果 -->
      <div v-if="aiFilterStreamingResult" v-html="aiFilterStreamingResult">
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- 加载中时不显示取消按钮，完成后显示关闭按钮 -->
        <el-button v-if="!aiFilterLoading" @click="aiFilterDialogVisible = false">{{ aiFilterStreamingResult ? '关闭' : '取消' }}</el-button>

        <!-- 未加载且无结果时显示开始筛选按钮 -->
        <el-button v-if="!aiFilterStreamingResult && !aiFilterLoading" type="primary" @click="submitAiFilter" :disabled="!aiFilterQuery">
          开始筛选
        </el-button>

        <!-- 加载中时显示处理中按钮 -->
        <el-button v-if="aiFilterLoading" type="primary" loading>处理中...</el-button>

        <!-- 完成后显示重新输入按钮 -->
        <el-button v-if="aiFilterStreamingResult && !aiFilterLoading" type="primary" @click="resetInputOnly">
          重新输入
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getKolInfo, exportKolInfo, getCartList, createCart, getCartDetail, addKolToCart, removeKolFromCart, updateCartName, deleteCart, exportCartData } from '@/api'
import { marked } from 'marked'
import hljs from 'highlight.js'

export default {
  name: 'KolList',
  data () {
    return {
      loading: false,
      tableLoading: false,
      searchForm: {
        nick_name: '',
        plat: '',
        gender: '',
        page: 1,
        page_size: 10,
        price_type: '',
        price_range: '',
        min_price: null,
        max_price: null,
        min_follower: null,
        max_follower: null,
        sort_field: 'follower',
        sort_order: 'desc'
      },
      kolList: [],
      pagination: {
        total: 0,
        page: 1,
        page_size: 10,
        total_pages: 0
      },
      debounceTimer: null,
      exportList: [],
      dialogVisible: false,
      priceFilterVisible: false,
      followerFilterVisible: false,
      selectedFollowerRange: '',
      selectedPriceRange: '',
      tempPriceFilter: {
        price_type: '',
        min_price: null,
        max_price: null,
        selectedRange: ''
      },
      tempFollowerFilter: {
        min_follower: null,
        max_follower: null,
        selectedRange: ''
      },
      tableHeight: 500,
      // 选号车相关数据
      cartList: [],
      activeCart: null,
      newCartDialogVisible: false,
      newCartForm: {
        name: '我的选号车'
      },
      renameCartDialogVisible: false,
      renameCartForm: {
        cart_id: '',
        name: ''
      },
      priceTypeDialogVisible: false,
      priceTypeForm: {
        cart_id: '',
        star_id: '',
        plat: 1,
        price_type: 'price_1_20'
      },
      cartFormRules: {
        name: [
          { required: true, message: '请输入选号车名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      // 添加当前KOL价格数据
      currentKolPrices: {
        price_1_20: 0,
        price_20_60: 0,
        price_60: 0,
        price_180: 0
      },
      // 添加加载状态标志
      addingToCart: false,
      // 添加导出加载状态
      exporting: false,
      // 添加打开导出对话框状态
      openingExportDialog: false,
      // 添加清空状态
      clearing: false,
      aiFilterDialogVisible: false,
      aiFilterLoading: false,
      aiFilterQuery: '',
      aiFilterStreamingResult: '',
      additionalHighlightWords: ['推荐', '建议', '适合', '最佳选择', '优选', '总成本', '预算', '面膜', '美妆', '护肤', '颜值', '女性', '年轻', '创意', '性价比']
    }
  },
  created () {
    this.fetchKolList()
    // 从本地存储加载清单列表
    const savedExportList = localStorage.getItem('kolExportList')
    if (savedExportList) {
      this.exportList = JSON.parse(savedExportList)
    }
    // 获取选号车列表
    this.fetchCartList()
    // 配置marked选项
    marked.setOptions({
      gfm: true,
      breaks: true,
      headerIds: true,
      mangle: false,
      tables: true,
      pedantic: false,
      sanitize: false,
      smartLists: true,
      smartypants: true,
      highlight: function (code, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(code, { language: lang }).value;
          } catch (e) {
            console.log(e);
            return code;
          }
        }
        return hljs.highlightAuto(code).value;
      }
    })
  },
  mounted () {
    // 初始计算表格高度
    this.calculateTableHeight()

    // 监听窗口大小变化
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    // 移除事件监听
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    getPriceTypes () {
      const commonTypes = [
        { label: '1-20s', value: 'price_1_20' },
        { label: '20-60s', value: 'price_20_60' }
      ]

      if (this.searchForm.plat === '4') { // B站
        return [
          { label: '定制视频', value: 'price_1_20' },
          { label: '植入视频', value: 'price_20_60' },
          { label: '直发动态', value: 'price_60' },
          { label: '转发动态', value: 'price_180' }
        ]
      } else if (this.searchForm.plat === '2') { // 小红书
        return [
          { label: '图文报价', value: 'price_1_20' },
          { label: '视频报价', value: 'price_20_60' }
        ]
      } else { // 抖音、快手
        return [
          ...commonTypes,
          { label: '60s以上', value: 'price_60' }
        ]
      }
    },
    hasPriceFilter () {
      return this.searchForm.price_type || this.searchForm.min_price || this.searchForm.max_price
    },
    hasFollowerFilter () {
      return this.searchForm.min_follower || this.searchForm.max_follower
    },
    hasActiveFilters () {
      return this.searchForm.gender ||
        this.searchForm.plat ||
        this.hasPriceFilter ||
        this.hasFollowerFilter;
    },
    getPriceFilterText () {
      if (this.searchForm.min_price || this.searchForm.max_price) {
        if (this.searchForm.min_price && this.searchForm.max_price) {
          return `${this.searchForm.min_price}-${this.searchForm.max_price}元`;
        }
        if (this.searchForm.min_price) {
          return `>${this.searchForm.min_price}元`;
        }
        return `<${this.searchForm.max_price}元`;
      }
      return '';
    },
    getFollowerFilterText () {
      if (this.searchForm.min_follower && this.searchForm.max_follower) {
        return `${this.formatNumber(this.searchForm.min_follower)}-${this.formatNumber(this.searchForm.max_follower)}`;
      }
      if (this.searchForm.min_follower) {
        return `>${this.formatNumber(this.searchForm.min_follower)}`;
      }
      if (this.searchForm.max_follower) {
        return `<${this.formatNumber(this.searchForm.max_follower)}`;
      }
      return '';
    },
    // 添加有效价格类型计算属性
    isValidPriceType () {
      const priceType = this.priceTypeForm.price_type;
      return priceType && this.currentKolPrices[priceType] && this.currentKolPrices[priceType] > 0;
    }
  },
  methods: {
    async fetchKolList () {
      // 设置最小loading时间，避免闪烁
      const minLoadingTime = 500
      const startTime = Date.now()

      this.tableLoading = true

      try {
        const res = await getKolInfo(this.searchForm)

        if (!res.data) {
          this.kolList = []
          this.pagination = {
            total: 0,
            page: 1,
            page_size: 10,
            total_pages: 0
          }
          return
        }

        // 确保list是数组
        if (!Array.isArray(res.data.list)) {
          this.kolList = []
        } else {
          // 处理每个达人数据，确保content_theme_labels_180d是数组
          this.kolList = res.data.list.map(item => {
            // 处理content_theme_labels_180d，将字符串转为数组
            if (typeof item.content_theme_labels_180d === 'string' && item.content_theme_labels_180d) {
              item.content_theme_labels_180d = item.content_theme_labels_180d.split('、');
            } else if (!Array.isArray(item.content_theme_labels_180d)) {
              item.content_theme_labels_180d = [];
            }

            // 处理tags_relation，将字符串转为对象格式
            if (typeof item.tags_relation === 'string' && item.tags_relation) {
              // 对于小红书平台，保留原始字符串格式
              if (item.plat === 2) {
                // 不做处理，保持字符串格式
              } else {
                // 其他平台转为对象格式
                const mainCategory = item.tags_relation;
                item.tags_relation = { [mainCategory]: [] };
              }
            } else if (!item.tags_relation) {
              item.tags_relation = {};
            }

            // 统一处理涨粉率数据格式
            if (item.plat === 4 || item.plat === 2) { // B站或小红书
              // B站和小红书的涨粉率数据除以100，转换为小数形式
              if (item.fans_increment_rate_within_15d) {
                item.fans_increment_rate_within_15d = Number(item.fans_increment_rate_within_15d) / 100;
              }
              if (item.interact_rate_within_30d) {
                item.interact_rate_within_30d = Number(item.interact_rate_within_30d) / 100;
              }
              if (item.play_over_rate_within_30d) {
                item.play_over_rate_within_30d = Number(item.play_over_rate_within_30d) / 100;
              }
            }

            return item;
          })
        }

        this.pagination = res.data.pagination || {
          total: 0,
          page: 1,
          page_size: 10,
          total_pages: 0
        }

        // 数据加载完成后重新计算表格高度
        this.$nextTick(() => {
          this.calculateTableHeight()
        })
      } catch (error) {
        this.$message.error('获取数据失败，请稍后重试')
        this.kolList = []
      } finally {
        this.tableLoading = false
      }
    },
    handleSizeChange (val) {
      this.searchForm.page_size = val
      this.searchForm.page = 1
      this.fetchKolList()
    },
    handleCurrentChange (val) {
      this.searchForm.page = val
      this.fetchKolList()
    },
    handleSearch () {
      this.searchForm.page = 1
      this.fetchKolList()
    },
    handleClear () {
      this.searchForm.page = 1
      this.fetchKolList()
    },
    getPlatformName (plat) {
      const platformMap = {
        '1': '抖音',
        '2': '小红书',
        '3': '快手',
        '4': 'B站',
        '5': '微信视频号',
        '6': '微信公众号',
        '7': '微博'
      }
      return platformMap[plat] || '-'
    },
    formatNumber (num) {
      if (!num && num !== 0) return '-';

      // 处理负数情况
      const isNegative = num < 0;
      const absNum = Math.abs(num);

      let formattedNum;
      if (absNum >= 10000) {
        formattedNum = (absNum / 10000).toFixed(1) + 'w';
      } else {
        formattedNum = absNum.toString();
      }

      // 如果是负数，添加负号
      return isNegative ? '-' + formattedNum : formattedNum;
    },
    formatPercentage (value) {
      if (!value && value !== 0) return '-';

      // 所有平台的数据都是小数形式，统一乘以100显示为百分比
      return (Number(value) * 100).toFixed(2) + '%';
    },
    // 添加防抖搜索方法
    debounceSearch () {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }
      this.debounceTimer = setTimeout(() => {
        this.handleSearch()
      }, 300) // 300ms 的防抖延迟
    },
    getMainCategory (tags) {
      if (!tags) return '-';
      // 如果是数组(B站的情况)，直接返回第一个标签
      if (Array.isArray(tags)) {
        return tags[0] || '-';
      }
      // 如果是字符串，检查是否包含分隔符
      if (typeof tags === 'string') {
        // 如果包含分隔符，返回第一个标签
        if (tags.includes('、')) {
          return tags.split('、')[0];
        }
        return tags;
      }
      // 其他平台的情况(对象格式)
      return Object.keys(tags)[0] || '-';
    },
    getTagsSubCategories (tags) {
      if (!tags) return '';
      // 如果是数组(B站的情况)，返回除第一个标签外的其他标签
      if (Array.isArray(tags)) {
        return tags.slice(1).join('、') || '-';
      }
      // 如果是字符串，检查是否包含分隔符
      if (typeof tags === 'string') {
        // 如果包含分隔符，返回完整字符串
        if (tags.includes('、')) {
          return tags;
        }
        return '';
      }
      // 其他平台的情况(对象格式)
      const mainCategory = Object.keys(tags)[0];
      if (!mainCategory || !Array.isArray(tags[mainCategory])) return '';
      return tags[mainCategory].join('、') || '-';
    },
    formatTagsRelation (tags) {
      if (!tags) return [];

      // 如果是抖音平台，已经在模板中特殊处理，这里不需要处理
      if (typeof tags === 'object' && !Array.isArray(tags)) {
        const formattedTags = [];
        for (const category in tags) {
          formattedTags.push(category);
          if (Array.isArray(tags[category])) {
            formattedTags.push(...tags[category]);
          }
        }
        return formattedTags.filter(tag => tag && typeof tag === 'string');
      }

      // 处理数组格式
      if (Array.isArray(tags)) {
        return tags.filter(tag => tag && typeof tag === 'string');
      }

      return [];
    },
    addToExportList (kol) {
      if (!this.isInExportList(kol.star_id)) {
        // 设置加载状态
        this.$set(kol, 'addingToExport', true);

        // 使用setTimeout模拟异步操作，防止界面卡顿
        setTimeout(() => {
          this.exportList.push(kol);
          // 保存到本地存储
          this.saveExportListToStorage();
          // 清除加载状态
          this.$set(kol, 'addingToExport', false);
        }, 300);
      }
    },
    isInExportList (starId) {
      return this.exportList.some(item => item.star_id === starId);
    },
    handleExport () {
      // 防止重复点击
      if (this.openingExportDialog) return;

      this.openingExportDialog = true;
      // 使用setTimeout模拟异步操作，防止界面卡顿
      setTimeout(() => {
        this.dialogVisible = true;
        this.openingExportDialog = false;
      }, 300);
    },
    removeFromExportList (starId) {
      const index = this.exportList.findIndex(item => item.star_id === starId);
      if (index !== -1) {
        // 找到表格中对应的行
        const kol = this.kolList.find(item => item.star_id === starId);
        const exportKol = this.exportList[index];

        // 设置加载状态
        if (kol) {
          this.$set(kol, 'removingFromExport', true);
        }
        // 为导出列表中的项也设置加载状态
        this.$set(exportKol, 'removingFromExport', true);

        // 使用setTimeout模拟异步操作，防止界面卡顿
        setTimeout(() => {
          this.exportList.splice(index, 1);
          // 保存到本地存储
          this.saveExportListToStorage();
          // 清除加载状态
          if (kol) {
            this.$set(kol, 'removingFromExport', false);
          }
        }, 300);
      }
    },
    clearExportList () {
      // 防止重复点击
      if (this.clearing) return;

      this.$confirm('确定要清空清单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.clearing = true;
        // 使用setTimeout模拟异步操作，防止界面卡顿
        setTimeout(() => {
          this.exportList = [];
          this.saveExportListToStorage();
          this.dialogVisible = false;
          this.clearing = false;
        }, 300);
      }).catch(() => { });
    },
    saveExportListToStorage () {
      localStorage.setItem('kolExportList', JSON.stringify(this.exportList))
    },
    async confirmExport () {
      // 防止重复点击
      if (this.exporting) return;

      this.exporting = true;
      try {
        const platformKols = this.exportList.reduce((acc, kol) => {
          const platform = kol.plat.toString();
          if (!acc[platform]) {
            acc[platform] = [];
          }
          acc[platform].push(kol.star_id);
          return acc;
        }, {});

        const response = await exportKolInfo({ platform_kols: platformKols });

        // 检查响应类型
        if (response.data.type && response.data.type.includes('json')) {
          const reader = new FileReader();
          reader.onload = () => {
            const error = JSON.parse(reader.result);
            this.$message.error(error.sub_msg || '导出失败');
          };
          reader.readAsText(response.data);
          return;
        }

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // 从响应头获取文件名并解码，去掉UTF-8前缀
        const contentDisposition = response.headers['content-disposition'];
        let filename = '达人数据.xlsx';
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (filenameMatch && filenameMatch[1]) {
            filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''))
              .replace(/^UTF-8/, ''); // 去掉UTF-8前缀
          }
        }

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        this.exportList = [];
        localStorage.removeItem('kolExportList');
        this.dialogVisible = false;
        this.$message.success('清单导出成功');
      } catch (error) {
        console.error('清单导出失败:', error);
        this.$message.error(error.response?.data?.sub_msg || '导出失败，请稍后重试');
      } finally {
        this.exporting = false;
      }
    },
    handlePriceRangeChange (value) {
      if (value === '') {
        this.searchForm.min_price = null
        this.searchForm.max_price = null
        return
      }

      if (value !== 'custom') {
        const ranges = {
          '10w+': [100000, null],
          '5w-10w': [50000, 100000],
          '1w-5w': [10000, 50000],
          '5k-1w': [5000, 10000],
          '2k-5k': [2000, 5000],
          '0-2k': [0, 2000]
        }
        const [min, max] = ranges[value]
        this.searchForm.min_price = min
        this.searchForm.max_price = max
      }
    },
    resetPriceFilter () {
      this.searchForm.price_type = '';
      this.selectedPriceRange = '';
      this.searchForm.min_price = null;
      this.searchForm.max_price = null;
      this.priceFilterVisible = false;
      this.handleSearch();
    },
    applyPriceFilter () {
      this.priceFilterVisible = false;
      this.handleSearch();
    },
    handleSortChange ({ prop, order }) {
      this.searchForm.sort_field = prop
      this.searchForm.sort_order = order === 'descending' ? 'desc' : 'asc'
      this.fetchKolList()
    },
    goToKolDetail (kol) {
      // 在新窗口打开达人详情页
      const routeData = this.$router.resolve({
        path: '/kol/detail',
        query: { star_id: kol.star_id }
      });
      window.open(routeData.href, '_blank');
    },
    handleFollowerRangeChange (value) {
      if (value === '') {
        this.searchForm.min_follower = null
        this.searchForm.max_follower = null
        return
      }

      if (value !== 'custom') {
        const ranges = {
          '1000w+': [10000000, null],
          '500w-1000w': [5000000, 10000000],
          '100w-500w': [1000000, 5000000],
          '10w-100w': [100000, 1000000],
          '1w-10w': [10000, 100000],
          '0-1w': [0, 10000]
        }
        const [min, max] = ranges[value]
        this.searchForm.min_follower = min
        this.searchForm.max_follower = max
      }
    },
    resetFollowerFilter () {
      this.selectedFollowerRange = '';
      this.searchForm.min_follower = null;
      this.searchForm.max_follower = null;
      this.followerFilterVisible = false;
      this.handleSearch();
    },
    applyFollowerFilter () {
      this.followerFilterVisible = false;
      this.handleSearch();
    },
    clearFilter (field) {
      this.searchForm[field] = '';
      this.handleSearch();
    },
    clearAllFilters () {
      this.searchForm.gender = '';
      this.searchForm.plat = '';
      this.resetPriceFilter();
      this.resetFollowerFilter();
      this.handleSearch();
    },
    handlePricePopoverShow () {
      this.followerFilterVisible = false;
    },
    handleFollowerPopoverShow () {
      this.priceFilterVisible = false;
    },

    handleOutsideClick (event) {
      const isPricePopover = event.target.closest('.price-filter-popover');
      const isFollowerPopover = event.target.closest('.follower-filter-popover');
      const isPriceButton = event.target.closest('[slot="reference"]');
      const isFollowerButton = event.target.closest('[slot="reference"]');
      if (!isPricePopover && !isFollowerPopover && !isPriceButton && !isFollowerButton) {
        this.priceFilterVisible = false;
        this.followerFilterVisible = false;
      }
    },
    calculateTableHeight () {
      this.$nextTick(() => {
        const windowHeight = window.innerHeight
        const searchBoxHeight = document.querySelector('.search-box')?.offsetHeight || 0
        const searchHeaderHeight = document.querySelector('.search-header')?.offsetHeight || 0
        const activeFiltersHeight = document.querySelector('.active-filters')?.offsetHeight || 0
        const paginationHeight = document.querySelector('.pagination-container')?.offsetHeight || 0
        // 减小spacing值，避免过度留白
        const spacing = 120
        this.tableHeight = windowHeight - (searchBoxHeight + searchHeaderHeight + activeFiltersHeight + paginationHeight + spacing)
      })
    },

    handleResize: function () {
      const that = this
      if (this.resizeTimer) {
        clearTimeout(this.resizeTimer)
      }
      this.resizeTimer = setTimeout(() => {
        that.calculateTableHeight()
      }, 100)
    },
    tableRowClassName ({ row, rowIndex }) {
      return 'table-row';
    },
    handlePlatformChange (platform) {
      this.searchForm.plat = platform;
      this.handleSearch();
    },
    // 选号车相关方法
    async fetchCartList () {
      try {
        const res = await getCartList()
        if (res.code === 200 && res.data) {
          this.cartList = res.data
          // 如果有选号车，默认选中第一个
          if (this.cartList.length > 0 && !this.activeCart) {
            this.activeCart = this.cartList[0]
          }
        }
      } catch (error) {
        console.error('获取选号车列表失败:', error)
        this.$message.error('获取选号车列表失败')
      }
    },

    createNewCart () {
      this.newCartForm.name = '我的选号车'
      this.newCartDialogVisible = true
    },

    async submitNewCart () {
      try {
        const res = await createCart(this.newCartForm)
        if (res.code === 200) {
          this.$message.success('选号车创建成功')
          this.newCartDialogVisible = false
          await this.fetchCartList()
          // 设置新创建的选号车为当前选中的选号车
          this.activeCart = this.cartList.find(cart => cart.cart_id === res.data.cart_id)
        }
      } catch (error) {
        console.error('创建选号车失败:', error)
        this.$message.error('创建选号车失败')
      }
    },

    showRenameCartDialog (cart) {
      this.renameCartForm.cart_id = cart.cart_id
      this.renameCartForm.name = cart.name
      this.renameCartDialogVisible = true
    },

    async submitRenameCart () {
      try {
        const res = await updateCartName(this.renameCartForm)
        if (res.code === 200) {
          this.$message.success('选号车重命名成功')
          this.renameCartDialogVisible = false
          await this.fetchCartList()
          // 更新当前选中的选号车
          if (this.activeCart && this.activeCart.cart_id === this.renameCartForm.cart_id) {
            this.activeCart = this.cartList.find(cart => cart.cart_id === this.renameCartForm.cart_id)
          }
        }
      } catch (error) {
        console.error('重命名选号车失败:', error)
        this.$message.error('重命名选号车失败')
      }
    },

    confirmDeleteCart (cart) {
      this.$confirm(`确定要删除选号车"${cart.name}"吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          const res = await deleteCart({ cart_id: cart.cart_id })
          if (res.code === 200) {
            this.$message.success('选号车删除成功')
            await this.fetchCartList()
            // 如果删除的是当前选中的选号车，重新选择第一个选号车
            if (this.activeCart && this.activeCart.cart_id === cart.cart_id) {
              this.activeCart = this.cartList.length > 0 ? this.cartList[0] : null
            }
          }
        } catch (error) {
          console.error('删除选号车失败:', error)
          this.$message.error('删除选号车失败')
        }
      }).catch(() => { })
    },

    handleCartCommand (command) {
      if (command.type === 'select') {
        this.activeCart = this.cartList.find(cart => cart.cart_id === command.cartId)
      }
    },

    viewCartDetail () {
      if (this.activeCart) {
        // 在新窗口打开选号车详情页
        const routeData = this.$router.resolve({
          path: '/cart/detail',
          query: { cart_id: this.activeCart.cart_id }
        });
        window.open(routeData.href, '_blank');
      }
    },

    handleAddToCart (cartId, kol) {
      // 防止重复点击
      if (kol.addingToCart) return;

      // 设置加载状态
      this.$set(kol, 'addingToCart', true);

      this.priceTypeForm.cart_id = cartId
      this.priceTypeForm.star_id = kol.star_id
      this.priceTypeForm.plat = parseInt(kol.plat)

      // 保存当前KOL的价格信息
      this.currentKolPrices = {
        price_1_20: kol.price_1_20 || 0,
        price_20_60: kol.price_20_60 || 0,
        price_60: kol.price_60 || 0,
        price_180: kol.price_180 || 0
      }

      // 根据平台和价格自动选择第一个有效的价格类型
      let validPriceType = '';

      if (kol.plat === 4) { // B站
        if (kol.price_1_20 && kol.price_1_20 > 0) validPriceType = 'price_1_20'; // 定制视频
        else if (kol.price_20_60 && kol.price_20_60 > 0) validPriceType = 'price_20_60'; // 植入视频
        else if (kol.price_60 && kol.price_60 > 0) validPriceType = 'price_60'; // 直发动态
        else if (kol.price_180 && kol.price_180 > 0) validPriceType = 'price_180'; // 转发动态
      } else if (kol.plat === 2) { // 小红书
        if (kol.price_1_20 && kol.price_1_20 > 0) validPriceType = 'price_1_20'; // 图文报价
        else if (kol.price_20_60 && kol.price_20_60 > 0) validPriceType = 'price_20_60'; // 视频报价
      } else { // 抖音、快手
        if (kol.price_1_20 && kol.price_1_20 > 0) validPriceType = 'price_1_20'; // 1-20s
        else if (kol.price_20_60 && kol.price_20_60 > 0) validPriceType = 'price_20_60'; // 20-60s
        else if (kol.price_60 && kol.price_60 > 0) validPriceType = 'price_60'; // 60s以上
      }

      this.priceTypeForm.price_type = validPriceType;

      // 如果没有有效价格，提示用户
      if (!validPriceType) {
        this.$message.warning('该达人没有有效的价格信息，无法添加到选号车');
        // 清除加载状态
        this.$set(kol, 'addingToCart', false);
        return;
      }

      this.priceTypeDialogVisible = true;

      // 弹窗关闭时清除加载状态
      this.$once('hook:updated', () => {
        if (this.priceTypeDialogVisible) {
          const clearLoading = () => {
            this.$set(kol, 'addingToCart', false);
          };

          // 监听弹窗关闭事件
          const unwatch = this.$watch('priceTypeDialogVisible', (newVal) => {
            if (!newVal) {
              clearLoading();
              unwatch(); // 取消监听
            }
          });
        } else {
          this.$set(kol, 'addingToCart', false);
        }
      });
    },

    async confirmAddToCart () {
      // 防止重复点击
      if (this.addingToCart) return;

      this.addingToCart = true;
      try {
        const res = await addKolToCart(this.priceTypeForm)
        if (res.code === 200) {
          this.$message.success(res.sub_msg || '达人已添加到选号车')
          this.priceTypeDialogVisible = false
          // 更新选号车列表
          await this.fetchCartList()

          // 找到对应的KOL并清除加载状态
          const kol = this.kolList.find(item => item.star_id === this.priceTypeForm.star_id);
          if (kol) {
            this.$set(kol, 'addingToCart', false);
          }
        } else {
          this.$message.error(res.sub_msg || '添加达人到选号车失败')
        }
      } catch (error) {
        console.error('添加达人到选号车失败:', error)
        this.$message.error(error.response?.data?.sub_msg || '添加达人到选号车失败')
      } finally {
        this.addingToCart = false
      }
    },

    async exportCartData () {
      if (!this.activeCart) {
        this.$message.warning('请先选择一个选号车')
        return
      }

      this.loading = true
      try {
        const response = await exportCartData({ cart_id: this.activeCart.cart_id })

        // 检查响应类型
        if (response.data.type && response.data.type.includes('json')) {
          const reader = new FileReader()
          reader.onload = () => {
            const error = JSON.parse(reader.result)
            this.$message.error(error.sub_msg || '导出失败')
          }
          reader.readAsText(response.data)
          return
        }

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url

        // 从响应头获取文件名并解码，去掉UTF-8前缀
        const contentDisposition = response.headers['content-disposition']
        let filename = `${this.activeCart.name}_${new Date().toISOString().replace(/[:.]/g, '_')}.xlsx`
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
          if (filenameMatch && filenameMatch[1]) {
            filename = decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''))
              .replace(/^UTF-8/, '') // 去掉UTF-8前缀
          }
        }

        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)

        this.$message.success('选号车导出成功')
      } catch (error) {
        console.error('选号车导出失败:', error)
        this.$message.error(error.response?.data?.sub_msg || '导出失败，请稍后重试')
      } finally {
        this.loading = false
      }
    },
    openAiFilter () {
      // 清空历史输入和结果
      this.aiFilterQuery = '';
      this.aiFilterStreamingResult = '';
      this.aiFilterLoading = false;

      // 打开对话框
      this.aiFilterDialogVisible = true;
    },

    // 只重置输入区域，保留历史输入内容
    resetInputOnly () {
      // 保留历史输入内容
      const historyQuery = this.aiFilterQuery;

      // 清空结果
      this.aiFilterStreamingResult = '';
      this.aiFilterLoading = false;

      // 使用setTimeout确保状态更新后再显示输入区域
      setTimeout(() => {
        // 恢复历史输入内容
        this.aiFilterQuery = historyQuery;
      }, 100);
    },

    handleAiFilterDialogClose (done) {
      console.log('关闭AI筛选对话框');

      // 如果正在加载，不允许关闭
      if (this.aiFilterLoading) {
        this.$message.warning('正在处理请求，请稍候...');
        return;
      }

      // 如果有流式结果，处理完整响应
      if (this.aiFilterStreamingResult) {
        this.aiFilterStreamingResult = this.processCompleteResponse(this.aiFilterStreamingResult);
      }

      // 关闭对话框
      if (typeof done === 'function') {
        done();
      } else {
        this.aiFilterDialogVisible = false;
      }
    },
    async submitAiFilter () {
      if (!this.aiFilterQuery) {
        this.$message.warning('请输入筛选条件');
        return;
      }

      // 重置结果并设置加载状态
      this.aiFilterLoading = true;
      this.aiFilterStreamingResult = '正在处理您的请求，请稍候...';

      // 获取token
      const token = localStorage.getItem('token');
      if (!token) {
        this.$message.error('您尚未登录，请先登录');
        this.aiFilterLoading = false;
        return;
      }

      try {
        let fullResponse = '';

        // 发起请求
        const response = await fetch('https://fcback-kol-info.souxiu.cn/api/dashscope/agent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept': 'text/event-stream'
          },
          body: JSON.stringify({
            query: this.aiFilterQuery,
            format: 'markdown'
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('请求成功，开始处理流式响应');

        // 处理流式响应
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        // 保留初始提示文本直到收到第一个响应
        let isFirstChunk = true;

        // 处理流
        const processStream = async () => {
          console.log('开始处理流');
          try {
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                // 流结束，处理完整响应
                console.log('流处理完成，处理完整响应');
                // 将处理后的完整响应设置为流式结果
                const processedResponse = this.processCompleteResponse(fullResponse);
                this.aiFilterStreamingResult = processedResponse;
                this.aiFilterLoading = false;
                break;
              }

              // 解码数据
              const chunk = decoder.decode(value, { stream: true });
              console.log('收到数据块:', chunk);

              // 处理SSE格式的数据
              const lines = chunk.split('\n');
              for (const line of lines) {
                if (line.startsWith('data:')) {
                  try {
                    const data = JSON.parse(line.substring(5));
                    if (data.text) {
                      console.log('收到文本:', data.text);

                      // 收到第一个响应时，清空初始提示文本
                      if (isFirstChunk) {
                        this.aiFilterStreamingResult = '';
                        isFirstChunk = false;
                      }

                      // 使用新方法更新流式结果
                      this.updateStreamingResult(data.text);
                      // 累积完整响应
                      fullResponse += data.text;
                    }
                  } catch (e) {
                    console.error('解析SSE数据出错:', e);
                  }
                }
              }
            }
          } catch (error) {
            console.error('流处理过程中出错:', error);
            this.$message.error('处理数据时出错，请稍后重试');
            this.aiFilterLoading = false;
          }
        };

        // 开始处理流
        await processStream();

      } catch (error) {
        console.error('AI智能筛选请求出错:', error);
        this.$message.error('AI智能筛选请求失败，请稍后重试');
        this.aiFilterLoading = false;
      }
    },

    processCompleteResponse (fullResponse) {
      // 如果响应为空，直接返回
      if (!fullResponse) return '';

      // 配置 marked 选项
      marked.setOptions({
        gfm: true,
        breaks: true,
        headerIds: true,
        mangle: false,
        tables: true,
        pedantic: false,
        sanitize: false,
        smartLists: true,
        smartypants: true,
        highlight: function (code, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(code, { language: lang }).value;
            } catch (e) {
              console.log(e);
              return code;
            }
          }
          return hljs.highlightAuto(code).value;
        }
      });

      // 处理响应中的特殊字符和空行
      let processedResponse = fullResponse
        .replace(/\r\n/g, '\n')
        .replace(/\r/g, '\n')
        // 处理连续的空行，最多保留两个
        .replace(/\n{3,}/g, '\n\n')
        // 确保表格前后有空行
        .replace(/([^\n])\n(\|[^\n]+\|)/g, '$1\n\n$2')
        .replace(/(\|[^\n]+\|)\n([^\n])/g, '$1\n\n$2');

      // 使用 marked 解析 Markdown
      let htmlContent = marked(processedResponse);

      // 查找并替换普通表格为Element UI表格
      htmlContent = this.convertTableToElementUI(htmlContent);

      return htmlContent;
    },

    // 将HTML中的表格转换为Element UI表格
    convertTableToElementUI (htmlContent) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;

      // 查找所有表格
      const tables = tempDiv.querySelectorAll('table');
      tables.forEach(table => {
        // 创建Element UI表格容器
        const elTableContainer = document.createElement('div');
        elTableContainer.className = 'el-table-responsive';

        // 获取表头行
        const thead = table.querySelector('thead');
        const headerRow = thead ? thead.querySelector('tr') : null;

        // 获取数据行
        const tbody = table.querySelector('tbody');
        const dataRows = tbody ? tbody.querySelectorAll('tr') : [];

        // 创建Element UI表格
        const elTable = document.createElement('div');
        elTable.className = 'el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition';
        elTable.style.width = '100%';

        // 创建表头
        if (headerRow) {
          const elTableHeader = document.createElement('div');
          elTableHeader.className = 'el-table__header-wrapper';
          const headerTable = document.createElement('table');
          headerTable.className = 'el-table__header';
          const headerThead = document.createElement('thead');
          const headerTr = document.createElement('tr');
          headerTr.className = 'el-table__row';

          const headerCells = headerRow.querySelectorAll('th');
          headerCells.forEach(cell => {
            const elTh = document.createElement('th');
            elTh.className = 'el-table_1_column_1 is-leaf el-table__cell';
            elTh.innerHTML = cell.innerHTML;
            headerTr.appendChild(elTh);
          });

          headerThead.appendChild(headerTr);
          headerTable.appendChild(headerThead);
          elTableHeader.appendChild(headerTable);
          elTable.appendChild(elTableHeader);
        }

        // 创建表体
        const elTableBody = document.createElement('div');
        elTableBody.className = 'el-table__body-wrapper is-scrolling-none';
        const bodyTable = document.createElement('table');
        bodyTable.className = 'el-table__body';
        const bodyTbody = document.createElement('tbody');

        dataRows.forEach(row => {
          const elTr = document.createElement('tr');
          elTr.className = 'el-table__row';

          const cells = row.querySelectorAll('td');
          cells.forEach(cell => {
            const elTd = document.createElement('td');
            elTd.className = 'el-table_1_column_1 el-table__cell';
            elTd.innerHTML = cell.innerHTML;
            elTr.appendChild(elTd);
          });

          bodyTbody.appendChild(elTr);
        });

        bodyTable.appendChild(bodyTbody);
        elTableBody.appendChild(bodyTable);
        elTable.appendChild(elTableBody);

        // 替换原始表格
        elTableContainer.appendChild(elTable);
        table.parentNode.replaceChild(elTableContainer, table);
      });

      return tempDiv.innerHTML;
    },

    updateStreamingResult (text) {
      // 如果是第一次更新，初始化结果
      if (!this.aiFilterStreamingResult) {
        this.aiFilterStreamingResult = '';
      }

      // 直接拼接新文本，不进行markdown转换
      this.aiFilterStreamingResult += text;

      // 将完整的文本内容进行markdown转换
      let htmlContent = marked(this.aiFilterStreamingResult, {
        gfm: true,
        breaks: true,
        tables: true,
        sanitize: false,
        smartLists: true,
        highlight: function (code, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(code, { language: lang }).value;
            } catch (e) {
              return code;
            }
          }
          return hljs.highlightAuto(code).value;
        }
      });

      // 转换表格为Element UI表格
      htmlContent = this.convertTableToElementUI(htmlContent);

      // 更新DOM
      this.$nextTick(() => {
        const container = document.querySelector('.el-dialog__body');
        if (container) {
          // 使用v-html指令显示转换后的HTML内容
          container.innerHTML = htmlContent;
          // 滚动到底部
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    formatAiResult (result) {
      // 这个方法可以保留，但不再需要进行Markdown格式化，因为MarkdownRenderer组件会处理
      return result;
    },
    copyAiResult () {
      try {
        const textToCopy = this.aiFilterStreamingResult || this.aiFilterResult;
        if (!textToCopy) return;

        // 创建一个临时textarea元素
        const textarea = document.createElement('textarea');
        textarea.value = textToCopy;
        document.body.appendChild(textarea);

        // 选择文本并复制
        textarea.select();
        document.execCommand('copy');

        // 移除临时元素
        document.body.removeChild(textarea);

        // 显示成功提示
        this.$message.success('已复制到剪贴板');
      } catch (error) {
        console.error('复制结果时出错:', error);
        this.$message.error('复制失败，请手动选择并复制');
      }
    },
    resetAiFilter () {
      console.log('重置AI筛选');

      // 清空所有状态
      this.aiFilterResult = null;
      this.aiFilterStreamingResult = '';
      this.aiFilterQuery = '';
      this.aiFilterLoading = false;

      // 使用setTimeout确保状态更新后再打开对话框
      setTimeout(() => {
        this.aiFilterDialogVisible = true;
      }, 100);
    },
    // 添加应用筛选结果的方法
    applyAiFilterResult () {
      try {
        // 保存结果并关闭对话框
        if (this.aiFilterStreamingResult) {
          const processedResult = this.processCompleteResponse(this.aiFilterStreamingResult);
          this.aiFilterResult = processedResult;

          // 使用setTimeout确保状态更新后再关闭对话框
          setTimeout(() => {
            this.aiFilterDialogVisible = false;

            // 滚动到结果区域
            this.$nextTick(() => {
              const resultElement = document.querySelector('.ai-filter-result');
              if (resultElement) {
                resultElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            });
          }, 100);
        }
      } catch (error) {
        console.error('应用筛选结果时出错:', error);
        this.$message.error('应用筛选结果时出错，请稍后重试');
      }
    },
    formatTableContent (content) {
      if (!content) return '';

      // 1. 将所有内容按行分割
      let lines = content.split('\n');

      // 2. 处理表格内容
      let isInTable = false;
      let formattedLines = [];
      let columnWidths = [];

      for (let line of lines) {
        // 检测是否是表格行
        if (line.includes('|')) {
          isInTable = true;

          // 移除行首行尾的空格和多余的|
          line = line.trim().replace(/^\||\|$/g, '');

          // 分割单元格
          let cells = line.split('|').map(cell => cell.trim());

          // 更新列宽
          cells.forEach((cell, index) => {
            columnWidths[index] = Math.max(columnWidths[index] || 0, cell.length);
          });

          // 重建表格行
          line = '| ' + cells.join(' | ') + ' |';
        } else {
          if (isInTable) {
            // 添加空行来分隔表格
            formattedLines.push('');
            isInTable = false;
          }
        }

        formattedLines.push(line);
      }

      return formattedLines.join('\n');
    },
  }
}
</script>

<style>
::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 16px;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  min-height: 28px;
  padding: 100px 0 0;
  border-style: solid;
  border-color: transparent;
  border-width: 4px !important;
  background-color: #dadce0;
  border-radius: 8px;
  box-shadow: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bdc1c6;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}

/* AI筛选结果表格样式 */
.el-dialog__body .el-table__header {
  width: 100% !important;
  table-layout: fixed;
}

.el-dialog__body .el-table__body {
  width: 100% !important;
  table-layout: fixed;
}

.el-dialog__body .el-table__header-wrapper th {
  padding: 12px 0;
  font-weight: bold;
  background-color: #f5f7fa;
  color: #606266;
  text-align: left;
  padding-left: 10px;
}

.el-dialog__body .el-table__body-wrapper td {
  padding: 12px 0;
  border-bottom: 1px solid #ebeef5;
  text-align: left;
  padding-left: 10px;
}

.el-dialog__body .el-table__row:hover td {
  background-color: #f5f7fa;
}

/* 表格中链接的样式 */
.el-dialog__body .el-table a {
  color: #409EFF;
  text-decoration: none;
}

.el-dialog__body .el-table a:hover {
  color: #66b1ff;
  text-decoration: underline;
}

/* 确保对话框内的内容样式统一 */
.el-dialog__body {
  padding: 20px;
  line-height: 1.6;
  color: #606266;
}

.el-dialog__body h1,
.el-dialog__body h2,
.el-dialog__body h3,
.el-dialog__body h4 {
  margin-top: 1.5em;
  margin-bottom: 0.8em;
  color: #303133;
}

.el-dialog__body p {
  margin-bottom: 1em;
}

.el-dialog__body ul,
.el-dialog__body ol {
  padding-left: 2em;
  margin-bottom: 1em;
}

.el-dialog__body blockquote {
  padding: 0 1em;
  border-left: 4px solid #dfe2e5;
  color: #6a737d;
  margin-bottom: 1em;
}

.el-dialog__body pre {
  background-color: #f6f8fa;
  border-radius: 3px;
  padding: 16px;
  overflow: auto;
  margin-bottom: 1em;
}

.el-dialog__body code {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
}
</style>

<style scoped>
/* 基础布局样式 */
div[v-loading] {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.el-table {
  margin: 15px 20px !important;
  width: calc(100% - 40px) !important;
  flex: 1;
}

.search-box {
  padding: 20px 20px 0 20px;
}

.search-header {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.pagination-container {
  padding: 0 20px 20px 20px;
  text-align: right;
  position: relative;
  z-index: 1;
}

/* 用户信息样式 */
.user-info {
  display: flex;
  flex-direction: column;
}

.nickname-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  font-weight: 500;
}

.sub-info {
  font-size: 12px;
  color: #909399;
  margin-top: 4px;
}

/* 表格样式 */
.el-table {
  border-radius: 4px;
  overflow: hidden;
}

.el-table th {
  color: #606266;
  font-weight: 500;
}

.el-table td {
  padding: 12px 0;
}

.el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #015ddc;
  color: #ffffff;
}

.el-table--enable-row-hover .el-table__body tr:hover>td .sub-info {
  color: #e0e0e0 !important;
}

.el-table--enable-row-hover .el-table__body tr:hover>td .nickname-text {
  color: #ffffff !important;
}

/* 标签容器样式 */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

/* 价格相关样式 */
.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 0;
}

.price-label {
  text-align: left;
  color: #606266;
}

.price-value {
  text-align: right;
  margin-left: 4px;
  color: #303133;
}

/* 导出列表样式 */
.export-list-container {
  max-height: 400px;
  overflow-y: auto;
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
}

.export-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 10px;
}

/* 筛选面板样式 */
.filter-panel {
  padding: 20px;
}

.section-title {
  font-size: 14px;
  color: #606266;
  margin-bottom: 16px;
}

/* 价格类型部分样式 */
.price-type-section {
  margin-bottom: 24px;
}

.price-type-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.price-range-section {
  padding-top: 20px;
  border-top: 1px solid #EBEEF5;
}

.price-range-presets .el-radio-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* 自定义范围输入样式 */
.custom-range {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 16px 0;
}

.custom-range .el-input-number {
  width: 130px;
}

/* 筛选操作按钮样式 */
.filter-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #EBEEF5;
}

/* Radio按钮样式 */
.el-radio {
  margin-right: 0;
  margin-bottom: 0;
  line-height: 1.5;
  white-space: nowrap;
}

/* 粉丝数范围部分样式 */
.follower-range-section {
  margin-bottom: 24px;
}

.follower-range-presets .el-radio-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* 筛选标签样式 */
.filter-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}

/* 弹出层样式 */
.price-filter-popover,
.follower-filter-popover {
  z-index: 2000;
}

/* 操作按钮样式 */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

/* 平台选项卡样式 */
.platform-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 800px;
}

.platform-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  padding: 12px 0;
  position: relative;
  flex: 1;
  color: #606266;
  border: none;
  background: transparent;
  text-align: center;
}

.platform-tab:hover {
  color: #409EFF;
}

.platform-tab.active {
  color: #409EFF;
  font-weight: 500;
}

.platform-tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #409EFF;
}

.platform-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-bottom: 0;
}

.platform-filter {
  margin-bottom: 20px;
}

/* 选号车样式 */
.cart-dropdown {
  margin-right: 10px;
}

.cart-button {
  position: relative;
}

.cart-badge {
  position: absolute;
  top: -8px;
  right: -8px;
}

.cart-dropdown-menu {
  min-width: 250px;
  padding: 0;
}

.cart-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #EBEEF5;
  font-weight: bold;
}

.cart-dropdown-footer {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  border-top: 1px solid #EBEEF5;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-item-info {
  display: flex;
  flex-direction: column;
}

.cart-name {
  font-size: 14px;
  color: #303133;
}

.cart-count {
  font-size: 12px;
  color: #909399;
  margin-top: 4px;
}

.cart-actions {
  display: flex;
  gap: 10px;
}

.cart-actions i {
  cursor: pointer;
  color: #909399;
  font-size: 16px;
}

.cart-actions i:hover {
  color: #409EFF;
}

.cart-actions i.el-icon-delete:hover {
  color: #F56C6C;
}

.active-cart {
  background-color: #F5F7FA;
}

.empty-cart-tip {
  color: #909399;
  font-size: 14px;
}

.export-actions {
  display: flex;
  align-items: center;
}

/* 价格值样式 */
.price-value {
  color: #F56C6C;
  font-weight: bold;
  margin-left: 8px;
}

/* 禁用的radio样式 */
.el-radio.is-disabled .el-radio__label {
  color: #C0C4CC;
}

/* 确保价格类型弹窗中的radio垂直排列 */
.el-dialog .el-radio-group {
  display: flex;
  flex-direction: column;
}

.el-dialog .el-radio {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .platform-tabs {
    flex-wrap: wrap;
  }

  .platform-tab {
    flex: 0 0 25%;
  }

  .search-box {
    padding: 10px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .custom-range {
    flex-direction: column;
    align-items: stretch;
  }

  .custom-range .el-input-number {
    width: 100%;
  }
}
</style>
